<template>
    <DsTooltip>
        <template #reference>
            <div :class="['comment-indicator', { compact }]">
                <DsIcon name="message-square" />
                <strong>{{ commentCount }}</strong>
                <span v-if="showLabel">{{ commentCount === 1 ? 'Comment' : 'Comments' }}</span>
            </div>
        </template>

        <template #default>
            {{ commentCount === 1 ? 'Comment' : 'Comments' }}
        </template>
    </DsTooltip>
</template>

<script>
export default {
    props: {
        commentCount: Number,
        compact: Boolean,
        showLabel: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.comment-indicator {
    display: flex;
    grid-gap: $spacing-050;
    align-items: center;
    width: 100%;
    background-color: $color-gray-100;
    padding: $spacing-100;
    border-radius: calc($border-radius / 2);
    justify-content: center;
    --icon-size: #{$icon-size-sm};
    --icon-color: #{$color-gray-700};
    color: $color-gray-700;

    &.compact {
        // margin-top: $spacing-100;
        // padding: $spacing-050;
    }

    strong {
        font-size: $font-size-xs;
    }
}
</style>
