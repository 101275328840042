export const roles = {
    FE: 'Front end engineer',
    BE: 'Back end engineer',
    FS: 'Full stack engineer',
    DESIGNER: 'Product Designer',
    PM: 'Project Manager',
};

export default {
    roles,
};
